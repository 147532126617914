import React from "react";
import SEO from "../components/seo";
import "./404.scss";

export default function NotFoundPage(props) {
    return (
        <div location={props.location}>
            <SEO title="404 Not Found" language="en"/>
            <div>
                <div className="not-found">
                    <div className="container">
                        <h1 className="not-found__title">Page Not Found</h1>
                        <p className="not-found__subtitle">Oops, we couldn't find this page!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}